import { useEffect, useState } from "react";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import InfiniteTable from "../pages/InfiniteTable";
import { StyledDataGrid } from "../pages/StyledDataGrid";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import SingleDatePicker from "./form/formComponents/SingleDatePicker";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import { store } from "../redux/store";
import ExportReadingsButton from "./ExportReadingsButton";
import theme from "../theme";
import { setAllReadingsRecors } from "../redux/slices/meterSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorIcon from "@mui/icons-material/Error";
import { useSearchParams } from "react-router-dom";
/* import { useLazyGetAllReadingsQuery } from "../../redux/slices/meterApi"; */
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
const AllLastReadings = () => {
  const readings = useSelector((state) => state?.meterSlice?.allReadings);
  const allReadingsCount = useSelector(
    (state) => state?.meterSlice?.allReadingsCount
  );
  const haveRecords = useSelector((state) => state?.meterSlice?.haveRecords);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [refreshParams, setRefreshParams] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [getReadings] = store.useLazyGetAllReadingsQuery();
  const [formData, setFormData] = useState({});
  const now = new Date();
  const toDate = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1)
  );
  const paramsList = {
    page: page,
    per_page: perPage,
    /*  no_gateway: true, */
    most_recent_no_condominiums: true,
    message_type: "reading",
    to_date: formData?.to_date ?? now.toISOString(),
    only_readings: true,
  };

  const schema = {
    type: "object",
    properties: {
      to_date: {
        $id: "3",
        title: t("inserted_at"),
        type: "string",
        default: paramsList.to_date ?? "",
        label: t("end_date"),
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
      props: {
        className: "bntListSearch",
      },
    },
    to_date: {
      "ui:field": "datepicker",
    },
  };
  const fields = {
    datepicker: SingleDatePicker,
  };
  const onSubmitClick = () => {
    const messagesDateTo = new Date(formData.to_date);
    messagesDateTo.setUTCHours(23, 59, 59, 59);
    setPage(1);
    /*     messagesDateTo.setDate(messagesDateTo.getDate() + 1); */
    getReadings({
      ...paramsList,
      page: 1,
      to_date: messagesDateTo.toISOString(),
    });
  };

  useEffect(() => {
    const messagesDateTo = new Date(paramsList.to_date);
    messagesDateTo.setUTCHours(23, 59, 59, 59);
    /*   messagesDateTo.setDate(messagesDateTo.getDate() + 1); */
    getReadings({
      ...paramsList,
      to_date: messagesDateTo.toISOString(),
    });
  }, [page]);

  useEffect(() => {
    console.log("fmdt", formData);
  }, [formData]);
  const columns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return <>{t("dt", { val: new Date(params.row.messageDate) })}</>;
      },
    },

    {
      field: "strValue",
      headerName: t("value"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "pod",
      headerName: t("pod"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (!params.row.alarms) {
          return <>--</>;
        } else {
          return (
            <Box display={"flex"} flexWrap="wrap" width={"100%"}>
              {params.row.alarms?.map((item, index) => (
                <Chip
                  key={index}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Link to={`/meters/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },

    // data[2].attributes.values.values.value.last_reading.datetime_utc
  ];

  useEffect(() => {
    console.log("rdngs", readings);
  }, [readings]);
  const rows = readings?.map((item, i) => ({
    id: i,
    messageDate: item?.messageDate,
    strValue:
      item?.strValue !== null
        ? `${t("number", {
            val: item.strValue,
          })} ${item?.unit}`
        : "--",
    pod: item?.pod ?? "--",
    metrological_serial: item?.metrological_serial ?? "--",
    alarms: item?.alarms,
    actions: item?.deviceId,
  }));
  return (
    <Box sx={{ height: 700 }}>
      <Typography
        variant="h2"
        sx={{ mb: 2, color: theme?.palette?.primary?.main }}
      >
        {t("readings")}
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        onSubmit={(values) => {
          onSubmitClick(values.formData);
        }}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        validator={validator}
        children={true}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <ExportReadingsButton params={{ to_date: formData?.to_date }} />
          <Button sx={{ ml: 2 }} variant="contained" onClick={onSubmitClick}>
            {t("search")}
          </Button>
        </Box>
      </Form>
      {/*       <Box height={450} sx={{ mt: 4 }}>
        <StyledDataGrid
          disableColumnMenu
          rows={rows ?? []}
          columns={columns ?? []}
          pageSize={perPage}
   
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          rowCount={allReadingsCount}
          paginationMode="server"
          getRowHeight={() => "auto"}
 

          disableSelectionOnClick
       
        />
      </Box> */}
      <InfiniteTable
        styles={{ height: 450, mt: 4 }}
        rows={rows ?? []}
        columns={columns}
        page={page}
        perPage={perPage}
        setPage={setPage}
        paginationMode={"server"}
        /*   dataLength={balances?.length} */
        records={haveRecords}
        recordsFn={setAllReadingsRecors}
      />

      <IwdAuthWrapper
        children={
          <Button
            onClick={() => navigate("/readings/import")}
            sx={{ float: "right", mt: 2 }}
          >
            {t("import_readings")}
          </Button>
        }
        section={"import_readings_btn"}
      />
    </Box>
  );
};
export default AllLastReadings;
